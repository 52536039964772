export enum FeatureFlags {
  ApexMaintenanceWindow = "apex-maintenance-window",
  /**
   * FREC-3134: See https://frectalk.slack.com/archives/C05UQML76U8/p1703613215894049 for context
   */
  BypassPaymentSupportedInstitutionChecks = "bypass-payments-supported-institutions-check",
  BankAccountLimit = "bank_account_limit",
  ThemeToggle = "theme-toggle",
  EstimatedTaxImpact = "estimated-tax-impact",
  DiCustomizationLimitOverride = "di-customization-limit-override",
  JointAccountM0 = "joint-account-m-0",
  Allocation = "portfolio-allocation",
  DirectIndexReinvestChart = "direct-index-reinvest-chart",
  StockLending = "stock-lending",
  GlobalBanner = "global-banner",
  MarketingBanner = "marketing-banner",
  TradeRestriction = "trade-restriction",
  TLHModal = "tlh-modal",
}
